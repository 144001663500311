

// 字数（英文算半个）
export function getTextLength(str){
  str = str || "";
  let len = str.length;
  let reLen = 0;
  for (let i = 0; i < len; i++) {       
    if (str.charCodeAt(i) < 27 || str.charCodeAt(i) > 126) {
      reLen += 2;
    } else {
      reLen += 1;
    }
  }
  return Math.ceil(reLen / 2);
}

// 剪裁（英文算半个）
export function cutText(str, max, patch){
  str = str || ""
  if(getTextLength(str) <= max){
    return str
  }
  if(patch){
    while(getTextLength(str) > max-1){
      str = str.slice(0, str.length-1);
    }
    return str+patch;
  }else{
    while(getTextLength(str) > max){
      str = str.slice(0, str.length-1);
    }
    return str;
  }
}
