<template>
  <a-modal
    :visible="show"
    v-bind="$attrs"
    title="编辑口味"
    :afterClose="remove"
    width="25%"
    @cancel="remove"
    @ok="handleOk"
  >
    <div>
      <div
        class="flex items-center mt-2"
        v-for="(item, i) in newFlavorList"
        :key="item.label"
      >
        <!-- <span style="min-width:50px;">{{ item.name }}:</span> -->
        <div class="edit-class">
          <div class="flex center">
            <div style="width: 90px;">口味:</div>
            <a-input v-model="item.name" />
          </div>
          <div class="flex center" style="margin-top: 6px">
            <div style="width: 90px;">口味英文:</div>
            <a-input v-model="item.name_en" value="口味英文:" />
          </div>
        </div>
        <div>
          <a-button style="margin-left: 20px" type="link" @click="onFlavorDelete(i)">删除</a-button>
        </div>
      </div>
    </div>
    <div v-if="!newFlavorList.length" class="pl-1">
      <a-button type="link" @click="onFlavorAdd()">新增</a-button>
    </div>
  </a-modal>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    flavorList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newFlavorList: [],
    };
  },
  async mounted() {
    this.newFlavorList = _.cloneDeep(this.flavorList).map((item) => {
      return {
        label: item.name + ' ' + item.name_en,
        name: item.name,
        name_en:item.name_en,
        id: item.id,
      };
    });
  },
  methods: {
    remove() {
      this.$emit("update:show", false);
    },
    handleOk() {
      if (this.newFlavorList.filter((el) => el.name === "").length > 0) {
        this.$message.warning("口味不能为空");
        return;
      }
      console.log(this.newFlavorList);
      this.$emit("update:show", false);
      this.$emit("ok", this.newFlavorList);
    },

    onFlavorAdd() {
      this.newFlavorList.push({
        name: "",
        label: "",
        id: 0,
      });
    },

    onFlavorDelete(index) {
      this.newFlavorList.splice(index, 1);
    },
  },
};
</script>

<style>
.edit-class {
  width: 100%;
}
.flex.center{
  align-items: center;
}
</style>
