<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           :title="title"
           :afterClose="remove"
           width="500px"
           @cancel="remove"
           @ok="handleOk">
    <a-form>
      <div class="flex items-baseline overflow-auto"
           style="height:350px">
        <a-table rowKey="adcode"
                 :data-source="productionTimeList"
                 bordered
                 :pagination="false">
          <a-table-column width="200"
                          title="网点城市"
                          dataIndex="name"
                          algin="center"></a-table-column>
          <a-table-column title="制作时间(小时)"
                          dataIndex="price"
                          algin="center">
            <template slot-scope="text, record">
              <div class="flex items-center">
                <a-radio-group v-model="record.radio"
                               name="radioGroup"
                               :default-value="0">
                  <a-radio :value="0">
                    默认
                  </a-radio>
                  <a-radio :value="1">
                    使用区域时间
                  </a-radio>
                </a-radio-group>
                <a-input-number size="small"
                                :min="0"
                                :max="100000"
                                :step="0.5"
                                :disabled="record.radio == 0"
                                v-model="record.production_hour"
                                style="width:80px;" />
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </a-form>
  </a-modal>

</template>

<script>
import { getCityProductionTimeList } from '@/api/goods.js'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    skuId: {
      type: [String, Number],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    type () {
      return this.list.length > 0 ? 'edit' : 'add'
    }
  },
  data () {
    return {
      productionTimeList: []
    }
  },
  mounted () {
    if(this.type === 'add'){
      this.initData()
    }else{
      this.productionTimeList = this.format(this.list)
    }
  },
  methods: {
    async initData () {
      const { data } = await getCityProductionTimeList({ sku_id: this.skuId })
      this.productionTimeList = this.format(data.list)
    },

    format(list){
      list.map(item => {
        item.production_hour = item.production_time / 60
        if (item.production_time == 0) {
          item.radio = 0
        } else {
          item.radio = 1
        }
        return item
      })
      return list
    },
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      this.$emit('update:show', false)
      this.$emit('ok', {
        skuId: this.skuId,
        productionTimeList: this.productionTimeList.map(el=>{
          el.production_time = (el.radio == 1 ? el.production_hour * 60 : 0)
          return el
        })
      })
    },
  }
}
</script>

<style>
</style>