<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           :title="title"
           :afterClose="remove"
           width="40%"
           @cancel="remove"
           @ok="handleOk">
    <a-form>
      <div class="flex items-baseline overflow-auto"
           style="height:350px">
        <a-table rowKey="name"
                 :data-source="priceList"
                 bordered
                 :pagination="false">
          <a-table-column width="150"
                          title="售价区域"
                          dataIndex="name"
                          algin="center"></a-table-column>
          <a-table-column title="价格政策"
                          dataIndex="price"
                          algin="center">
            <template slot-scope="text, record">
              <div class="flex items-center">
                <a-radio-group v-model="record.radio"
                               name="radioGroup"
                               :default-value="0">
                  <a-radio :value="0">
                    默认价
                  </a-radio>
                  <a-radio :value="1">
                    区域价
                  </a-radio>
                </a-radio-group>
                <a-input-number size="small"
                                :min="0"
                                :max="100000"
                                :step="0.01"
                                :disabled="record.radio == 0"
                                v-model="record.price"
                                style="width:100px;" />
              </div>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </a-form>
  </a-modal>

</template>

<script>
import { getAreaSkuPirce } from '@/api/area-price.js'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    skuId: {
      type: [String, Number],
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    type () {
      return this.list.length > 0 ? 'edit' : 'add'
    }
  },
  data () {
    return {
      priceList: []
    }
  },
  mounted () {
    if(this.type === 'add'){
      this.initData()
    }else{
      this.priceList = this.list
    }
  },
  methods: {
    async initData () {
      const { data } = await getAreaSkuPirce({ sku_id: this.skuId })
      this.priceList = data.list.map(item => {
        item.regional_id = item.id
        if (item.price == '0.00') {
          item.radio = 0
        } else {
          item.radio = 1
        }
        return item
      })
    },
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      this.$emit('update:show', false)
      this.$emit('ok', {
        skuId: this.skuId,
        priceList: this.priceList.map(el=>{
          return {
            regional_id: el.regional_id,
            price: el.price
          }
        })
      })
    },
    onFlavorDelete (e) {
      console.log(e)
    }
  }
}
</script>

<style>
</style>