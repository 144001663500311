<template>
  <a-modal
    :visible="show"
    v-bind="$attrs"
    title="编辑尺寸"
    :afterClose="remove"
    width="20%"
    @cancel="remove"
    @ok="handleOk"
  >
    <div class="flex items-center mt-2" v-for="(item,i) in newFlavorList" :key="item.id">
      <!-- <span style="min-width:50px;">{{ item.name }}:</span> -->
      <a-input v-model="item.name" placeholder="中英共用" />
      <a-button type="link" @click="onFlavorDelete(i)">删除</a-button>
    </div>

    <div class="pl-1">
      <a-button type="link" @click="onFlavorAdd()">新增</a-button>
    </div>
  </a-modal>
</template>

<script>

import _ from 'lodash'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    specList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      newFlavorList: [],
    }
  },
  async mounted() {
    this.newFlavorList = _.cloneDeep(this.specList).map(item => {
      return {
        id: item.id,
        label: item.name,
        name: item.name,
        name_en: item.name,
      }
    })
  },
  methods: {
    remove() {
      this.$emit('update:show', false)
    },
    handleOk() {
      if(this.newFlavorList.filter(el=>el.name==='').length>0){
        this.$message.warning('尺寸不能为空')
        return
      }
      this.$emit('update:show', false)
      this.$emit('ok', this.newFlavorList)
    },

    onFlavorAdd(){
      this.newFlavorList.push({
        name: '',
        label: '',
        id: 0
      })
    },

    onFlavorDelete(index) {
      this.newFlavorList.splice(index, 1)
    }
  }
}
</script>

<style>
</style>