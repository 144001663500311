<template>
  <a-card>
    <a-form-model ref="ruleForm"
                  :model="formState"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">
      <!-- <a-form-model-item prop="goods_number" label="商品编码1" :rules="requiredRule">
        <a-input require v-model="formState.goods_number" />
      </a-form-model-item> -->

      <a-form-model-item prop="type" class="mb-2"
                         label="商品分类"
                         :rules="requiredRule" :wrapper-col="{span:14}">
        <a-radio-group v-model="formState.type"
                       button-style="solid"
                       :disabled="$route.query.type === 'edit'"
                       v-if="$route.query.type !== 'edit'"
                       @change="changeRadio">
          <a-radio-button v-for="(item,i) in Object.keys(goodsType)"
                          :key="i"
                          :value="Number(item)">{{ formatType(item) }}</a-radio-button>
        </a-radio-group>
        <a-radio-button v-else
                        checked
                        button-style="solid">{{formatType(formState.type) }}</a-radio-button>
      </a-form-model-item>
      <a-form-model-item prop="name" class="mb-2"
                         label="商品名称" :wrapper-col="{span:14}"
                         :rules="requiredRule">
        <a-input v-model="formState.name" />
      </a-form-model-item>
      <a-form-model-item prop="name_en" class="mb-2"
                         label="英文名称" :wrapper-col="{span:14}"
                         :rules="requiredRule">
        <a-input v-model="formState.name_en" />
      </a-form-model-item>
      <!-- <a-form-model-item prop="seo_name" class="mb-2"
                         label="搜索名" :wrapper-col="{span:14}">
        <a-input v-model="formState.seo_name" placeholder="用于方便搜索的别名" />
      </a-form-model-item> -->
      <a-form-model-item prop="describe" class="mb-2"
                         label="商品介绍" :wrapper-col="{span:14}">
        <a-textarea showCount
                    v-model="formState.describe" @change="changeDescribe" />
      </a-form-model-item>
      <a-form-model-item prop="describe_en" class="mb-2"
                         label="英文介绍" :wrapper-col="{span:14}">
        <a-textarea showCount
                    v-model="formState.describe_en" @change="changeDescribe" />
      </a-form-model-item>
      <!-- <a-form-model-item prop="expiration_date" class="mb-2"
                         label="保质期" :wrapper-col="{span:14}">
        <a-input v-model="formState.expiration_date" />
      </a-form-model-item>-->
      <a-form-model-item prop="storage_mode" class="mb-2"
                         label="储藏方式" :wrapper-col="{span:14}">
        <a-input v-model="formState.storage_mode" />
      </a-form-model-item>
      <a-form-model-item prop="storage_mode_en" class="mb-2"
                         label="储藏方式(英文)" :wrapper-col="{span:14}">
        <a-input v-model="formState.storage_mode_en" />
      </a-form-model-item>
      <a-form-model-item prop="batching" class="mb-2"
                         label="主要原料" :wrapper-col="{span:14}">
        <a-input v-model="formState.batching" />
      </a-form-model-item> 
      <a-form-model-item prop="batching_en" class="mb-2"
                         label="主要原料(英文)" :wrapper-col="{span:14}">
        <a-input v-model="formState.batching_en" />
      </a-form-model-item>
      <a-form-model-item prop="shape_info" class="mb-2"
                         label="特殊形状" :wrapper-col="{span:14}">
        <a-input v-model="formState.shape_info" />
      </a-form-model-item> 
      <a-form-model-item prop="shape_info_en" class="mb-2"
                         label="特殊形状(英文)" :wrapper-col="{span:14}">
        <a-input v-model="formState.shape_info_en" />
      </a-form-model-item>
      <!-- <div v-if="formState.type === 3">
        <a-form-model-item prop="production_time"
                           label="制作时间">
          <a-input-number :min="1"
                          :max="200"
                          v-model="formState.production_time" />小时
        </a-form-model-item>
      </div> -->
      <a-form-model-item prop="cover_horizontal_path"
                         label="手机列表竖图">
        <uploadImage type="1"
                     :value.sync="formState.cover_horizontal_path" />
        <span class="img-tip">图片尺寸：780px*1520px，支持png、jpeg、jpg等</span>
      </a-form-model-item>
      <a-form-model-item prop="cover_big_path"
                         label="网站大图">
        <uploadImage type="6"
                     :value.sync="formState.cover_big_path" />
        <span class="img-tip">图片尺寸：1920px*1080px，支持png、jpeg、jpg等</span>
      </a-form-model-item>
      
      <!-- <a-form-model-item prop="cover_square_path"
                         label="列表方图">
        <uploadImage type="2"
                     :value.sync="formState.cover_square_path" />
      </a-form-model-item> -->
      <a-form-model-item label="商品方图" class="mb-2">
        <uploadImage type="4"
                     :value.sync="formState.detail_square_image_path[0]" />
        <span class="img-tip">图片尺寸：780px*840px，支持png、jpeg、jpg等</span>
      </a-form-model-item>
      <a-form-model-item label="商品横图" class="mb-2">
        <uploadImage type="3"
                     :value.sync="formState.detail_horizontal_image_path[0]" />
        <span class="img-tip">图片尺寸：724px*340px，支持png、jpeg、jpg等</span>
      </a-form-model-item>
      <a-form-model-item label="手机详情长图" class="mb-2">
        <uploadImage type="5"
                     multiple
                     :value="formState.introduce_image_path"  @getList="(list)=>{formState.introduce_image_path = list}" />
        <a-button style="position: absolute;right:0px;top:30px;"
                  type="link"
                  @click="handlerPreview">预览</a-button>
      </a-form-model-item>
      <a-form-model-item class="mb-2"
                         label="口味" :rules="requiredRule">
        <div class="flex">
          <a-select v-model="flavor_names_show"
                    mode="tags"
                    style="width: 100%"
                    placeholder="口味"
                    :open="false"
                    disabled></a-select>
          <a-button type="primary"
                    v-if="!isDetail"
                    @click="onFlavorEdit">编辑</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item class="mb-2"
                         label="尺寸" :rules="requiredRule">
        <div class="flex">
          <a-select v-model="specification_names_show"
                    mode="tags"
                    style="width: 100%"
                    placeholder="尺寸"
                    :open="false"
                    disabled></a-select>
          <a-button type="primary"
                    v-if="!isDetail"
                    @click="onSpceEdit">编辑</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="SKU" class="mb-2">
        <base-table :columnsData="skuCakeColumns"
                    rowKey="key"
                    :tableData="skuList"
                    :customHeight="400"
                    :rowClassName="getSkuRowClass"
                    style="margin-left:-10px;">
          <template #sku_number="{ record }">
            <a-input v-model="record.sku_number" />
          </template>
          <template #price="{ record }">
            <a-input-number v-model="record.price"
                            :min="0"
                            :max="100000"
                            :step="0.01" />
          </template>

          <template #flavor_name="{ text }">
            <span>{{ text == '' ? '-' : text }}</span>
          </template>

          <template #specification_name="{ text }">
            <span>{{ text == '' ? '-' : text }}</span>
          </template>

          <template #area_price="{ record }">
            <a @click="showAreaPrice(record)"
               type="link">查看</a>
          </template>
          <template #diners_number_config="{ record }">
            <div class="flex items-center">
              <a-input-number :min="1"
                              v-model="record.diners_num_min" />
              <div style="width:20px;">~</div>
              <a-input-number :min="1"
                              v-model="record.diners_num_max" />人
            </div>
          </template>

          <template #production_hour="{ record }">
            <a-input-number v-model="record.production_hour"
                            :min="0"
                            :max="100000"
                            style="width:70px;"
                            :step="0.5" />
          </template>
          <template #production_time_city="{ record }">
            <a @click="showTimeCity(record)"
               type="link">查看</a>
          </template>
          <template #horizontal_image_path="{ record }">
            <uploadImage type="2"
                     :value.sync="record.horizontal_image_path" />
          </template>
          <template #status="{ record }">
            <a-button @click="updateStatus(record)"
                      type="link">{{ record.status == '1' ? '禁用' : '恢复' }}</a-button>
          </template>
        </base-table>
      </a-form-model-item>
      <a-form-model-item v-if="formState.type != 3" prop="freight_type_id" class="mb-2"
                         label="运费模板" >
        <a-select v-model="formState.freight_type_id"
                  style="width: 100%"
                  placeholder="运费模板">
          <a-select-option v-for="item in freightList"
                           :key="item.id">{{ item.name }} （{{ item.freight }}元）</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="presell_time" 
            label="发售日期">
        <a-date-picker
          style="width: 400px;"
          v-model="formState.presell_time"
          valueFormat="YYYY-MM-DD"
          placeholder="如果是预售，请选择发售日期">
        </a-date-picker>
      </a-form-model-item>
        

      <a-form-model-item v-if="!isDetail" style="text-align:center;">
        <a-button type="primary"
                  @click="onSubmit">保存</a-button>
        <a-button @click="onCancel"
                  style="margin-left: 10px">取消</a-button>
      </a-form-model-item>
    </a-form-model>
    
    <flavor-modal v-if="isEditFlavor"
                  :show.sync="isEditFlavor"
                  :flavorList="flavor_list"
                  @ok="asyncSkuInfosFlavor" />
    <specification-modal v-if="isEditSpec"
                         :show.sync="isEditSpec"
                         :specList="specification_list"
                         @ok="asyncSkuInfosSpec" />
    <price-modal v-if="isEditPirce"
                 :title="priceTitle"
                 :show.sync="isEditPirce"
                 :skuId="activeSkuId"
                 :list="priceList"
                 @ok="onGetAreaPrice" />
    <production-time-modal v-if="isEditTime"
                           :title="priceTitle"
                           :show.sync="isEditTime"
                           :skuId="activeSkuId"
                           :list="productionTimeList"
                           @ok="onGetProductionTime" />

    <a-modal class="preview-class"
             title="详情描述"
             width="450px"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <div style="height:600px;overflow: auto;">
        <img style="width: 100%"
             v-for="(item,i) in formState.introduce_image_path"
             :key="i"
             :src="imgHost + item" />
      </div>
    </a-modal>

  </a-card>

</template>

<script>

import uploadImage from '@/components/upload-image'
import { requiredRule } from '@/hooks/use-form-rules'
import FlavorModal from './components/flavor-modal.vue'
import PriceModal from './components/price-modal.vue'

import { skuColumns, skuCakeColumns, goodsType, formatType } from "./columns"
import { getUUId } from '@/utils/index'
import { cutText } from '@/utils/string'
import { getFreightTypeList } from '@/api/activity/freight.js'
import _ from 'lodash'
import config from '@/utils/config'
import {
  getGoodsDetail,
  addGoods,
  eidtGoods
} from '@/api/goods'
import SpecificationModal from './components/specification-modal.vue'
import ProductionTimeModal from './components/production-time-modal.vue'


export default {
  components: {
    uploadImage,
    FlavorModal,
    PriceModal,
    SpecificationModal,
    ProductionTimeModal
  },
  data () {
    return {
      imgHost: config.HOST_IMG_URL,
      isDetail: false,
      previewVisible: false,
      skuColumns: skuColumns,
      skuCakeColumns: skuCakeColumns,
      goodsType: goodsType,
      requiredRule: requiredRule,
      baseSkuList: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 21 },
      formState: {
        goods_number: '',
        describe: '',
        name: "",
        name_en:"",
        delivery: false,
        type: 3,
        describe_en:"",
        storage_mode_en:'',
        storage_mode: '',
        batching:'',
        batching_en:'',
        shape_info:'',
        shape_info_en:'',
        // expiration_date: '',
        // batching: "",
        freight_type_id: "",
        cover_horizontal_path: '', // 封面长图 列表页用（先占用封面横图字段、不用加字段了）
        cover_square_path: '', // 列表方图
        introduce_image_path: [], //详情描述
        detail_square_image_path: [],
        detail_horizontal_image_path: [], // 详情横图
      },
      flavor_names_show: [],
      specification_names_show: [],
      skuList: [],
      flavor_list: [],
      specification_list: [],
      productionTimeList: [],
      isEditFlavor: false,
      isEditSpec: false,
      isEditTime: false,

      freightList: [],
      isEditPirce: false,
      activeSkuId: null,
      priceTitle: '',
      priceList: []
    }
  },

  created () {
    this.initData()
    if (this.$route.query.type === 'detail') {
      this.isDetail = true
    }
  },
  methods: {
    // 计算skuList
    computedSkuList () {
      const arr = [], 
            flavorArr = _.cloneDeep(this.flavor_list),
            specArr   = _.cloneDeep(this.specification_list)

      if (flavorArr.length === 0) {
        flavorArr.push('')
      }
      if (specArr.length === 0) {
        specArr.push('')
      }
      flavorArr.map((item) => {
        const fla_id = item.id
        specArr.map((sp) => {
          const sp_id = sp.id
          const obj = this.baseSkuList.find(el => {
            return fla_id && (el.flavor_id == fla_id) && sp_id && (el.specification_id === sp_id)
          })
          if (obj) {
            if (item.label) obj.flavor_name = item.name
            if (sp.label) obj.specification_name = sp.name
            arr.push(obj)
          } else {
            arr.push({
              key: getUUId(),
              sku_id: 0,
              sku_number: 0,
              flavor_id: 0,
              flavor_name: item.name || item,
              specification_name: sp.name || sp,
              specification_id: 0,
              price: 0,
              diners_number_config: '',
              diners_num_min: 1,
              diners_num_max: 1,
              // production_time: 0,
              production_hour: 0,
              status: 1,
              regional_pricing: [],
              outlets_city_production_time: []
            })
          }
        })
      })

      this.skuList = arr
    },

    // 编辑名称 更新SKU数据
    asyncSkuInfosFlavor (list) {
      this.flavor_list = list
      this.computedSkuList()
    },

    // 编辑名称 更新SKU数据
    asyncSkuInfosSpec (list) {
      this.specification_list = list
      this.computedSkuList()
    },

    // 描述限制字数
    changeDescribe(){
      this.formState.describe = cutText(this.formState.describe, 60)
    },

    async initData () {
      const { data } = await getFreightTypeList({ status: 1 })

      this.freightList = data.list
      if (this.$route.query.id) {
        const res = await getGoodsDetail({ goods_id: this.$route.query.id })

        let infos = { ...res.data, ...res.data.image }
        infos.detail_horizontal_image_path = res.data.image.detail_horizontal_image_list.map(item => {
          return item.image_path
        })
        infos.introduce_image_path = res.data.image.introduce_image_list.map(item => {
          return item.image_path
        })
        infos.detail_square_image_path = res.data.image.detail_square_image_list.map(item => {
          return item.image_path
        })
        // 口味
        this.flavor_list = [...res.data.flavor_list]
        this.specification_list = res.data.specification_list
        this.computedSkuList()

        this.formState = infos

        let skuArr = []
        res.data.sku_data.map((item) => {
          if (item.specification_list.length > 0) {
            item.specification_list.map((el) => {
              el.production_hour = el.production_time / 60
              let obj = {
                flavor_id: item.flavor_id,
                flavor_name: item.flavor_name,
              }
              obj.key = getUUId() + el.sku_id,
                el.regional_pricing = []
              if (res.data.type == 3)// 如果是蛋糕 填充食用人数
              {
                el.diners_num_min = el.diners_number_config.split(',')[0] || 0
                el.diners_num_max = el.diners_number_config.split(',')[1] || 0
              }
              obj = { ...obj, ...el }
              skuArr.push(obj)
            })
          }
        })

        // if (res.data.type === 3) {
        this.skuColumns = this.skuCakeColumns
        // } else {
        //   this.skuColumns = skuColumns
        // }

        this.skuList = skuArr
        this.baseSkuList = skuArr
        // this.rowSpan('flavor_name')
      } else {
        this.skuList.push({
          key: getUUId(),
          flavor_name: '',
          specification_name: '',
          price: 0,
          diners_number_config: '1,1',
          diners_num_min: 1,
          diners_num_max: 1,
          status: 1,
          sku_id: 0,
          sku_number: 0,
          regional_pricing: [],
          outlets_city_production_time: []
        })
      }
    },
    // 保存
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(!this.flavor_list.length){
            this.$message.error("请完善口味信息")
            return false;
          }
          if(!this.specification_list.length){
            this.$message.error("请完善尺寸信息")
            return false;
          }

          let params = { ...this.formState }

          // params.cover_horizontal_path = params.detail_horizontal_image_path.length > 0 ? params.detail_horizontal_image_path[0] : ''
          params.cover_square_path = params.detail_square_image_path.length > 0 ? params.detail_square_image_path[0] : ''

          params.detail_horizontal_image_path = params.detail_horizontal_image_path.join(',')
          params.detail_square_image_path = params.detail_square_image_path.join(',')

          params.introduce_image_path = params.introduce_image_path.join(',')

          params.flavor_data = JSON.stringify(this.flavor_list.map(el => {
            return {
              name: el.name,
              name_en: el.name_en,
              id: el.id || 0
            }
          }))
          params.specification_data = JSON.stringify(this.specification_list.map(el => {
            return {
              name: el.name,
              name_en: el.name,
              id: el.id || 0
            }
          }))

          // 组装SKU数据
          let isPrice = true
          const flavorList = []
          this.skuList.map((item) => {
            if (item.price == 0) {
              isPrice = false
            }

            let flavor = flavorList.find(el => el.flavor_name == item.flavor_name)
            let sku_item = {
              specification_name: item.specification_name,
              price: item.price,
              diners_number_config: (item.diners_num_min || 0) + ',' + (item.diners_num_max || 0),
              status: item.status,
              sku_id: item.sku_id,
              sku_number: item.sku_number,
              horizontal_image_path: item.horizontal_image_path || '',
              production_time: item.production_hour * 60,
              regional_pricing: item.regional_pricing || [],
              outlets_city_production_time: (item.outlets_city_production_time || []).map(el=>{
                return {
                  adcode: el.adcode,
                  production_time: el.production_time
                }
              })
            }
            if(flavor){
              flavor.specification_list.push(sku_item)
            }else{
              flavorList.push({
                // flavor_id: item.flavor_id, // 服务端不需要
                flavor_name: item.flavor_name,
                specification_list: [sku_item]
              })
            }
          })

          if (!isPrice) {
            this.$message.warning('所有SKU价格不能为0')
            return
          }
          params.sku_data = JSON.stringify(flavorList)

          // 蛋糕没有运费模板
          if(params.type == 3){
            params.freight_type_id = 0
          }

          //如果是编辑
          if (this.$route.query.type == 'edit') {
            eidtGoods(params).then(res => {
              if (res.code === 0) {
                this.$message.success('修改商品成功！')
              }
            })
          } else {
            addGoods(params).then(res => {
              if (res.code === 0) {
                this.$message.success('新增商品成功！')
                this.$router.push({ path: '/goods' })
              }
            })
          }
        } else {
          this.$message.error("请完善必填信息后在提交")
          return false;
        }
      });
    },
    changeRadio (e) {
      this.formState.type = e.target.value
      // 如果选择的是蛋糕，则sku中需要填写可食用人数字段
      if (e.target.value === 3) {
        this.skuColumns = this.skuCakeColumns
      } else {
        this.skuColumns = skuColumns
      }
    },
    onFlavorEdit () {
      this.isEditFlavor = true
    },

    onSpceEdit () {
      this.isEditSpec = true
    },

    // 切换sku状态
    updateStatus (record) {
      record.status = record.status == 1 ? 0 : 1
    },
    showAreaPrice (record) {
      this.priceTitle = record.flavor_name + '  |  ' + record.specification_name
      this.activeSkuId = record.sku_id
      this.isEditPirce = true
      if (record.regional_pricing.length > 0) {
        this.priceList = record.regional_pricing
      }
    },

    // 显示编辑城市制作时间
    showTimeCity (record) {
      this.priceTitle = record.flavor_name + '  |  ' + record.specification_name
      this.activeSkuId = record.sku_id
      // if (record.outlets_city_production_time && record.outlets_city_production_time.length > 0) {
        this.productionTimeList = record.outlets_city_production_time || []
      // }
      this.isEditTime = true
    },

    //表格确定回调
    handleChangeIndex (value, key, column) {
      const dataSource = [...this.skuList]
      const target = dataSource[key]
      if (target) {
        target[column] = value
        this.skuList = dataSource
      }
    },
    onCancel () {
      this.$router.go(-1)
    },

    onGetAreaPrice (data) {
      const { skuId, priceList } = data

      this.skuList = this.skuList.map(item => {
        if (item.sku_id == skuId) {
          item.regional_pricing = priceList
        }
        return item
      })
    },

    onGetProductionTime (data) {
      const { skuId, productionTimeList } = data
      this.skuList = this.skuList.map(item => {
        if (item.sku_id == skuId) {
          item.outlets_city_production_time = productionTimeList
        }
        return item
      })
    },

    getSkuRowClass (record) {
      if (record.status == 0) {
        return 'sku-disable-status'
      } else {
        return 'sku-normal-status'
      }
    },
    formatType,

    // 预览详情描述
    handlerPreview () {
      this.previewVisible = true
    }

  },
  watch: {
    'flavor_list' (vals) {
      return this.flavor_names_show = vals.map(el=>{
        return el.name + ' ' + el.name_en
      })
    },
    'specification_list' (vals) {
      return this.specification_names_show = vals.map(el=>{
        return el.name
      })
    }
  }

}
</script>

<style lang="less">
.sku-disable-status {
  background: rgb(205, 205, 205);
  opacity: 0.8;
  &:hover {
    background: rgb(205, 205, 205);
    opacity: 0.8;
  }
}
.img-tip{
  position: absolute;
  width: 600px;
  margin-top: -30px;
}
</style>